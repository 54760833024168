<template>
  <page-wrapper :no-access="!user.isAdmin">
    <v-container>
      <v-row class="my-10">
        <v-col cols="2"></v-col>
        <v-col cols="8" align-self="center">
          <v-text-field outlined label="Поиск" placeholder="ID, Имя или cft" filled v-model="search" @keydown.enter.stop="runSearch"/>
          <v-card>
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-card-title>Настройки</v-card-title>
              <v-spacer />
              <v-btn icon @click="settings = !settings">
                <v-icon>{{ settings ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-app-bar>
            <v-expand-transition>
              <v-card-text v-if="settings">
                <v-row>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                        v-model="selected"
                        label="Типы документов"
                        value="doc"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                        v-model="selected"
                        label="Справочники"
                        value="ref"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                        v-model="selected"
                        label="Аттрибуты"
                        value="att"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                        v-model="selected"
                        label="Значения справочников"
                        value="refv"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                        v-model="selected"
                        label="Документы ID"
                        value="docid"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                        v-model="selected"
                        label="Документы номер"
                        value="docregnum"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expand-transition>
          </v-card>
          <v-row>
            <v-col cols="12">
              <v-card class="my-10" v-if="searchResults && searchResults.length > 0">
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Тип
                        </th>
                        <th class="text-left">
                          Имя
                        </th>
                        <th class="text-left">
                          Сылка
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="sr in searchResults"
                          :key="sr.id"
                      >
                        <td class="text-left">
                          <span v-if="sr.type === 'doc'">Тип документов</span>
                          <span v-else-if="sr.type === 'ref'">Справочник</span>
                          <span v-else-if="sr.type === 'refv'">Значение справочника</span>
                          <span v-else-if="sr.type === 'att'">Аттрибут в документах</span>
                          <span v-else-if="sr.type === 'docid'">Документ ID</span>
                          <span v-else-if="sr.type === 'docregnum'">Документ номер</span>
                        </td>
                        <td class="text-left">{{sr.name}}</td>
                        <td class="text-left">
                          <router-link :to="searchResponseTypeToLink(sr)" custom v-slot="{ navigate }">
                            <v-btn icon @click="navigate"><v-icon>mdi-call-made</v-icon></v-btn>
                          </router-link>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </page-wrapper>
</template>

<script>
import PageWrapper from "../components/PageWrapper";


export default {
  name: "Search",
  data: function () {
    return {
      selected: [],
      settings: true,
      search: '',
    }
  },
  watch: {
    $route(to) {
      this.setStateFromQuery(to)
    },
    selected: function(){
      this.runSearch()
    }
  },
  computed: {
    searchResults() {
      return this.$store.getters.searchResults
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    runSearch() {
      if (this.search.length < 3 || this.selected.length === 0) {
        return
      }
      let fields = ''
      if (this.selected) {
        fields = encodeURIComponent(this.selected.join(','))
      }

      this.$router.replace({ query: {search: encodeURIComponent(this.search), fields: fields} }).catch(() => {})
      this.$store.dispatch('search', {
        search: this.search,
        fields: fields,
      })
    },
    searchResponseTypeToLink(val) {
      if (val.type === 'doc') {
        return "/documents/" + val.id + "/configuration"
      } else if (val.type === 'att') {
        return "/documents/" + val.id + "/configuration?search=" + encodeURIComponent(this.search)
      } else if (val.type === 'ref') {
        return '/reference/'+ val.id
      } else if (val.type === 'refv') {
        return '/reference/'+ val.id
      } else if (val.type === 'docid') {
        return '/documents/0/'+ val.id+'/main'
      } else if (val.type === 'docregnum') {
        return '/documents/0/'+ val.id+'/main'
      }
      return '/'
    },
    setStateFromQuery(route) {
      this.$store.commit('cleanUpSearch')
      if (route.query.search) {
        this.search = decodeURIComponent(route.query.search)
      }
      if (route.query.fields) {
        this.selected = decodeURIComponent(route.query.fields).split(',')
      } else {
        this.selected = []
      }
    }
  },
  components: {
    'PageWrapper': PageWrapper,
  },
  mounted() {
    this.setStateFromQuery(this.$route)
  }
}
</script>

<style scoped>

</style>